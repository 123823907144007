import '../scss/qcustomer_front.scss';
"use-strict"
let jQuery = require('jquery');

window.J = jQuery;
window.Jd = J(document);

Jd.ready(function(){

    console.log('*** QCUSTOMER FRONT ***');

    // MOBILE PHONE CUSTOM DANS LA FICHE D'INFORMATION CLIENT
    if (J('#field-mobile_phone').length) {
        var mobilePhone = J('#field-mobile_phone').val();
        mobilePhone = mobilePhone.replace('+33', '');

        J('#field-mobile_phone').removeAttr('required').attr('required', 'required');

        J('#field-mobile_phone').val(mobilePhone);
        J('<span style="position: absolute;color:  #333333;font-size: 16px;z-index: 88;top: 21px;left: 19px;">+33</span>').insertBefore(J('#field-mobile_phone'));
    }

    J('#field-mobile_phone').css({
        'padding-left' : '35px'
    });

    // PHONE DANS l'ADRESSE CLIENT
    if (J('#field-phone').length) {
        var mobilePhone = J('#field-phone').val();
        mobilePhone = mobilePhone.replace('+33', '');

        if (J('#field-phone').parent().parent().children('.form-control-comment').length) {
            J('#field-phone').parent().parent().children('.form-control-comment').remove();
        }
        J('#field-phone').removeAttr('required').attr('required', 'required');


        J('#field-phone').val(mobilePhone)
        J('<span style="position: absolute;color:  #333333;font-size: 16px;z-index: 88;top: 21px;left: 19px;">+33</span>').insertBefore(J('#field-phone'));
    }

    J('#field-phone').css({
        'padding-left' : '35px'
    });


    // DATE DE NAISSANCE
    if (J('#field-birthday').length) {

        J('#field-birthday').on('keyup keydown keypress', function(){

            var value = J(this).val(), valueLength = value.length;

            if (valueLength > 9) {
                value = value.substring(0, 10);
                J(this).val(value);
                return;
            }

            if(valueLength === 2 || valueLength === 5){
                value += '/';
                J(this).val(value);
            }
        });
    }
});